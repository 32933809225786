<template>
    <div class="bgc-white p-20 bd" id="mainContent">
      <h4 class="c-grey-900">{{ $t("editCoach") }}</h4>
      <div class="mT-30">
        <form @submit="checkForm">
          <div class="form-row mT-20">
            <div class="form-group col-md-6">
              <label for="inputEmail4">{{ $t("en_name") }}</label
              ><input type="name" class="form-control" id="inputEmail4" v-model="en_name" required />
            </div>
            <div class="form-group col-md-6">
              <label for="inputEmail4">{{ $t("ar_name") }}</label
              ><input type="name" class="form-control" id="inputEmail4" v-model="ar_name" required />
            </div>
          </div>
          <div class="form-row mT-20">
            <loading v-show="loading" />
          <sui-button
            type="submit"
            positive
            class="btn btn-primary mL-10"
            :content="$t('edit')"
            icon="edit"
            label-position="left"
            :disabled="loading"
            v-show="!loading"
          />
          <sui-button
            type="button"
            :content="$t('cancel')"
            :disabled="loading"
            v-show="!loading"
            @click="$emit('closePanel', {})"
          />
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      reload: { type: Function },
      coach: {}
    },
    created() {
        this.ar_name = this.coach.ar_name;
        this.en_name = this.coach.en_name;
    },
    data() {
      return {
        en_name: "",
        ar_name: "",
        loading: false,
      }
    },
    methods: {
      checkForm: function (e) {
        e.preventDefault();
        this.loading = true;
        this.editCoach();
      },
      editCoach: function () {
        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };
        this.$http
          .patch(
            "/backend/coaches/edit",
            {
              en_name : this.en_name,
              ar_name: this.ar_name,
              coach_id: this.coach.id
            },
            { headers }
          )
  
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 200:
                  this.$emit("closePanel", {});
                  this.success(this.$t("success"));
                  this.reload();
                  break;
              } 
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
              }
            }
          );
      },
      error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function () {
        this.$toast.success(this.$t("success"), {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style scoped>
  #mainContent {
    text-align: right;
    direction: rtl;
  }
  
  </style>